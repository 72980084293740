*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav-container {
}

nav .active-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

ion-icon .active {
  display: none;
}

nav .active-nav li div {
}

nav .active-nav li a {
  display: none;
}

nav .active-nav li a:hover {
  opacity: 1;
}

nav .options-nav {
}

.link-close {
  background: url('images/close-icon.svg');
  display: block;
  background-position: center;
  background-size: 17px;
  height: 44px;
  width: 20px;
  background-repeat: no-repeat;
}

.active-nav li {
  transition: all 400ms ease;
}

.active-nav.hide li {
  opacity: 0;
  transform: scale(0.8);
  pointer-events: none;
}

/* Overlay Animation */

.overlay {
  transition: all 400ms ease;
}

/* Navigation Menu Items Transition Delay */

.active-nav li:nth-of-type(1),
.active-nav.hide li:nth-of-type(10) {
  transition-delay: 0ms;
}

.active-nav li:nth-of-type(2),
.active-nav.hide li:nth-of-type(9) {
  transition-delay: 30ms;
}

.active-nav li:nth-of-type(3),
.active-nav.hide li:nth-of-type(8) {
  transition-delay: 60ms;
}

.active-nav li:nth-of-type(4),
.active-nav.hide li:nth-of-type(7) {
  transition-delay: 90ms;
}

.active-nav li:nth-of-type(5),
.active-nav.hide li:nth-of-type(6) {
  transition-delay: 120ms;
}

.active-nav li:nth-of-type(6),
.active-nav.hide li:nth-of-type(5) {
  transition-delay: 150ms;
}

.active-nav li:nth-of-type(7),
.active-nav.hide li:nth-of-type(4) {
  transition-delay: 180ms;
}

.active-nav li:nth-of-type(8),
.active-nav.hide li:nth-of-type(3) {
  transition-delay: 210ms;
}

.active-nav li:nth-of-type(9),
.active-nav.hide li:nth-of-type(2) {
  transition-delay: 240ms;
}

.active-nav li:nth-of-type(10),
.active-nav.hide li:nth-of-type(1) {
  transition-delay: 270ms;
}

.active-nav li:nth-of-type(10),
.active-nav.hide li:nth-of-type(1) {
  transition-delay: 270ms;
}

.nav-container .active-nav {
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 0vh;
  justify-content: start;
  overflow: hidden;
  transition: all 1000ms ease;
}

body:not(.dark) .nav-container .active-nav{
  background: rgba(255, 255, 255, 0.93) !important;
}

body.dark .nav-container .active-nav{
  background: rgba(0, 0, 0, 0.93) !important;
}

.nav-container nav.menu-move-up {
  margin-top: -70px;
  transition: all 1000ms ease;
  transition-delay: 510ms;
}

.nav-container.active .active-nav {
  height: 100vh;
}

.nav-container .active-nav li {
  width: 100%;
  padding: 0 32px;
}

.nav-container .active-nav li:first-child {
  margin-top: 80px;
}

.nav-container .active-nav li a {
  padding: 16px 0;
  display: inline-block;
  border-bottom: 1px solid #616161;
  width: 100%;
  font-size: 30px;
  transform: translateY(80px);
  opacity: 0;
  transition: all 700ms ease;
}

.nav-container.active .active-nav li a {
  transform: translateY(0);
  opacity: 1;
}

nav .menu-icon-container {
  width: 25px;
  align-items: center;
  cursor: pointer;
  background-color:red;
}

nav .menu-icon {
  position: relative;
  margin-bottom:100px;
  width: 100%;
}

nav .menu-icon .line-1,
nav .menu-icon .line-2 {
  position: absolute;
  height: 1px;
  width: 100%;
  transition-property: transform, top;
  transition-delay: 0ms, 160ms;
  transition-duration: 200ms;
}


body:not(.dark) nav .menu-icon .line-1,
body:not(.dark) nav .menu-icon .line-2 {
  background: black;
}


body.dark nav .menu-icon .line-1,
body.dark nav .menu-icon .line-2 {
  background: #fff;
}




nav .menu-icon .line-1 {
  top: -4px;
}

nav .menu-icon .line-2 {
  top: 4px;
}

.nav-container.active nav .menu-icon-container .menu-icon .line-1 {
  top: 0;
  transform: rotateZ(45deg);
  transition-property: top, transform;
  transition-delay: 0ms, 160ms;
  transition-duration: 200ms;
}

.nav-container.active nav .menu-icon-container .menu-icon .line-2 {
  top: 0;
  transform: rotateZ(-45deg);
  transition-property: top, transform;
  transition-delay: 0ms, 160ms;
  transition-duration: 200ms;
}

/* Nav Animation */

.nav-container nav {
  transition: all 400ms ease;
}