
.nav-container {
    height: 44x;
    z-index: 1;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;

}







/* Media Queries */

@media (max-width: 1100px) {
        .nav-container nav {
            width: 100%;
        }
    }

    .nav-container .desktop-nav {
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        right:0;
        width: 100%;
        height: 0vh;
        background-color: #ffffff7d;   
        border-radius: 12px;
        position: relative;
        box-sizing: border-box;
        color: rgba(0,0,0,0.8);
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        background-blend-mode: overlay;
        justify-content: start;
        overflow: hidden;
        z-index: -3;
        transition: all 1000ms ease;
    }


    body.dark .nav-container .desktop-nav {
        background-color: #0000007d;   
        backdrop-filter: blur(25px);
        -webkit-backdrop-filter: blur(25px);
        color: rgba(0,0,0,0.8);
    }

    .nav-container.active .desktop-nav {
        height: 100vh;
    }

    /* Mobile Nav */

    /* Search Box */

   body.dark .mobile-search-container input {
        width: 100%;
        padding: 4px 36px;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        background: #1b1b1b;
        border: 0;
        color: #fff;
        text-decoration: wavy orange !important;
        outline: none;
    }

    ion-input {
        font-weight:400;
    }
    
    body .nav-container.active button.input-clear-icon.sc-ion-input-ios {
        filter: brightness(50%) saturate(100%);
    }

    body.dark .nav-container.active button.input-clear-icon.sc-ion-input-ios {
        filter: brightness(100) saturate(100%);
    }    

    body.dark .mobile-search-container input {
        width: 100%;
        padding: 4px 12px;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        border: 0;
        background-color: black;
        color: #616161 !important;
        border-radius: 8px;
        outline: none;
    }



    body:not(.dark) .mobile-search-container input {
        width: 100%;
        padding: 4px 12px;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        border: 0;
        color: #616161 !important;
        border-radius: 8px;
        outline: none;
    }
    
 

    .nav-container.active div.mobile-search-container {
display:flex;
        z-index: 9 !important;
    }

    .nav-container.active .mobile-search-container input {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        background:rgb(244 244 245);
        border: 0;
        color: #fff;
        border-radius: 8px;
        outline: none;
    }

    body.dark .nav-container.active .mobile-search-container input {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        background: #1b1b1b;
        border: 0;
        color: #fff;
        border-radius: 8px;
        outline: none;
    }


    nav .mobile-nav {
        display: flex;
        width: 100%;
        justify-content: space-between;
        list-style: none;
    }

    body.dark .mobile-search-container {
        position: relative;
        border-bottom: 1px solid #616161;
        display: flex;
        align-items: center;
        opacity: 0;
        z-index:100 !important;
        transition: all 600ms ease;
    }

    .mobile-search-container {
        position: relative;
        padding: 0px 16px 8px 8px;
        border-bottom: 1px solid rgb(244 244 245);
        display: flex;
        align-items: center;
        opacity: 0;
        z-index:100 !important;
        transition: all 600ms ease;
    }

    .nav-container.active .mobile-search-container {
        transform: rotateX(0deg);
        margin-top: 6px;
        opacity: 1;
    }

    .link-search {
        background: url('../images/search-icon.svg');
        display: block;
        background-position: center;
        background-size: 18px;
        height: 44px;
        filter: brightness(0) saturate(100%);
        width: 20px;
        background-repeat: no-repeat;
    }
    
    body.dark .link-search {
        filter: brightness(100%) saturate(100%);
    }



    .mobile-search-container .link-search {
        position: absolute;
        left: 24px;
        opacity: .5;
        background-size: 15px;
    }

        /* Nav Move Up */

       .move-up {
            margin-top: -70px;
        }

    /* Cancel Button */

    .mobile-search-container .cancel-btn {
        color: #ffa929;
        font-size: 17px;
        font-weight: 400;
        cursor: pointer;
        text-align: end;
        overflow: hidden;
        transition: all 400ms ease;
    }


    .mobile-search-container .search-bar {
        flex: 1;
    }

    /* Desktop Nav Move Down */

    .desktop-nav.move-down li:first-child {
        margin-top: 150px;
    }

    .desktop-nav.move-down li {
        opacity: 0;
        pointer-events: none;
    }

    
    /* Quick Links */

    .mobile-search-container .search-bar.active ~ .search-results {
        top: 100px;
        opacity:1;
        pointer-events: auto;
        transition-delay: 40ms;
    }

    .mobile-search-container .search-results {
        position: absolute;
        left: 0;
        right: 0;
        padding: 0 32px;
        opacity: 0;
        pointer-events: none;
        top: 10px;
        transition: all 400ms ease;
    }

    .mobile-search-container .search-results ul {
        list-style: none;
    }

    .mobile-search-container .search-results h2 {
        color: #86868b;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 400;
    }

    body.dark .mobile-search-container .search-results ul li a {
        padding: 16px 0;
        display: inline-block;
        text-decoration: none;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 1px solid #616161;
        width: 100%;
    }

    .mobile-search-container .search-results ul li a {
        padding: 16px 0;
        display: inline-block;
        text-decoration: none;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 1px solid rgb(244 244 245);
        width: 100%;
    }    

    body.dark .mobile-search-container .search-results ul li a {
        padding: 16px 0;
        display: inline-block;
        text-decoration: none;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 1px solid #616161;
        width: 100%;
    }

    .mobile-search-container .search-results ul li a:hover {
        color: #ffa929;
    }

    /* Nav Animation */

    .nav-container nav {
        transition: all 400ms ease;
    }


input,
div,
span,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
